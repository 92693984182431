import { Controller } from '@hotwired/stimulus';
import Pusher from 'pusher-js'
require("jquery-ui/ui/widgets/draggable");
require("jquery-ui/ui/widgets/resizable");

export default class extends Controller {
  static values = { preview: String, studio: String }

  connect() {
    const _this = this
    const preview = this.data.get('preview');
    const studio = this.data.get('studio');
    const domain = `${location.protocol}//${process.env.CORS_ALLOWED_DOMAIN}`
    var MIN_DISTANCE = 10;
    var guides = [];
    var innerOffsetX, innerOffsetY;
    let guestDropped = false
    window.resizeIframe = function(height, width, xPos, yPos, uuid) {
      var element = document.getElementById("iframe-holder-" + uuid);
      if(element) {
        element.style.height = height + "px";
        element.style.width = width + "px";
        element.style.top = yPos + "px";
        element.style.left = xPos + "px";
      }
    }
    if(preview == "true" && studio == "true") {
      parent.postMessage({
        event: "CANVAS_RELOADED"
      }, domain);
    }
    if(!window.pusher) {
      window.pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: process.env.PUSHER_CLUSTER
      });
      var projectChannel = window.pusher.subscribe("project_" + this.data.get('projectId'));
      window.pusher.subscribe("project_stream_" + this.data.get('projectId'));
      projectChannel.bind('live', function(data) {
        if(data.id == _this.data.get('layerId')) {
          if(data.live) {
            $('.live-indicator').addClass('live');
          } else {
            $('.live-indicator').removeClass('live');
          }
        }
      });
      var layerChannel = window.pusher.subscribe("layer_" + this.data.get('layerId'));
      layerChannel.bind('update', function(data) {
        Turbolinks.visit(window.location.href);
      });
      layerChannel.bind('make_visible', function(data) {
        var frameID = '#iframe-holder-' + data.uuid;
        $(frameID + ' iframe')[0].onload = function() {
          $(frameID).css({
            opacity: 1,
            visibility: 'visible'
          })
        }
        $(frameID + ' iframe').attr( 'src', function ( i, val ) { return val; });
      });
      layerChannel.bind('change_position', function(data) {
        var frameID = '#iframe-holder-' + data.uuid;
        $(frameID).css({ 'z-index': data.order });
      });
      layerChannel.bind('make_invisible', function(data) {
        var frameID = '#iframe-holder-' + data.uuid;
        $(frameID + ' iframe')[0].onload = function() {}
        $(frameID).css({
          opacity: 0,
          visibility: 'hidden'
        })
        setTimeout(function(){
          $(frameID + ' iframe').attr( 'src', function ( i, val ) { return val; });  
        }, 2000)        
      });
    }
    if(preview == "true") {
      window.addEventListener("message", event => {
        if (event.origin === domain) {
          const { data } = event
          if (data.event === "ELEMENT_UNSELECTED") {
            var iframeHolder = $(document).find('.iframe-holder.active')
            var iframe = iframeHolder.find('iframe')[0]
            if(iframe && iframe.src.includes(domain)) {
              iframe.contentWindow.postMessage({
                event: "ELEMENT_UNSELECTED"
              }, domain);
            }
            $(document).find('.iframe-holder').removeClass('active');
          }
          if (data.event === "ELEMENT_SELECTED") {
            $(document).find('.iframe-holder').removeClass('active');
            var iframeHolder = $(`#iframe-holder-${data.uuid}`);
            iframeHolder.addClass('active');
            var iframe = iframeHolder.find('iframe')[0]
            if(iframe && iframe.src.includes(domain)) {
              iframe.contentWindow.postMessage({
                event: "ELEMENT_SELECTED"
              }, domain);
            }
          }
        }
      })
      $('body').click(function(event){
        var target = $(event.target);
        if(target.is("body") && studio == "true") {
          var iframe = $(document).find('.iframe-holder.active iframe')[0]
          if(iframe && iframe.src.includes(domain)) {
            iframe.contentWindow.postMessage({
              event: "ELEMENT_UNSELECTED"
            }, domain);
          }
          parent.postMessage({
            event: "ELEMENT_UNSELECTED"
          }, domain);
        }
      })
      $('.iframe-holder').dblclick(function(){
        var id = $(this).attr('id').replace('iframe-holder', 'element-selector');
        if(studio == "true") {
          $(document).find('.iframe-holder').removeClass('active');
          $(this).addClass('active');
          var iframe = $(this).find('iframe')[0]
          if(iframe && iframe.src.includes(domain)) {
            iframe.contentWindow.postMessage({
              event: "ELEMENT_SELECTED"
            }, domain);
          }
          parent.postMessage({
            event: "ELEMENT_SELECTED",
            uuid: id.replace('element-selector-', '')
          }, domain);
        } else {
          window.parent.document.getElementById(id).click();
        }
      })
      $('.iframe-holder').draggable({
        containment: "parent",
        scroll: false,
        start: function(event, ui) {
          var elements = $('.center-marker, .iframe-holder').not(this).filter(function() {
           return !($(this).css('visibility') == 'hidden' || $(this).css('display') == 'none');
          })
          guides = $.map(elements, _this.computeGuidesForElement);
          innerOffsetX = event.originalEvent.offsetX;
          innerOffsetY = event.originalEvent.offsetY;
        },
        stop: function(event, ui) {
          $('#guide-v, #guide-h').hide();
          const url = $(this).data("url");
          const xPos = $(this).position().left;
          const yPos = $(this).position().top;
          _this.savePosition(url, xPos, yPos)
        },
        drag: function(event, ui) {
          var guideV, guideH, distV = MIN_DISTANCE + 1, distH = MIN_DISTANCE + 1, offsetV, offsetH;
          var chosenGuides = { top: { dist: MIN_DISTANCE + 1 }, left: { dist: MIN_DISTANCE + 1 } };
          var $t = $(this);
          var pos = { top: event.originalEvent.pageY - innerOffsetY, left: event.originalEvent.pageX - innerOffsetX };
          var w = $t.outerWidth() - 1;
          var h = $t.outerHeight() - 1;
          var elemGuides = _this.computeGuidesForElement(null,pos,w,h);
          $.each(guides, function(i, guide) {
            $.each(elemGuides, function(i, elemGuide) {
              if(guide.type == elemGuide.type) {
                var prop = guide.type == "h"? "top":"left";
                var d = Math.abs(elemGuide[prop] - guide[prop]);
                if(d < chosenGuides[prop].dist) {
                  chosenGuides[prop].dist = d;
                  chosenGuides[prop].offset = elemGuide[prop] - pos[prop];
                  chosenGuides[prop].guide = guide;
                }
              }
            });
          });
          if (chosenGuides.top.dist <= MIN_DISTANCE) {
            $("#guide-h").css( "top", chosenGuides.top.guide.top ).show();
            ui.position.top = chosenGuides.top.guide.top - chosenGuides.top.offset;
          } else {
            $("#guide-h").hide();
            ui.position.top = pos.top;
          }
          if(chosenGuides.left.dist <= MIN_DISTANCE ) {
            $("#guide-v").css( "left", chosenGuides.left.guide.left ).show();
            ui.position.left = chosenGuides.left.guide.left - chosenGuides.left.offset;
          } else {
            $("#guide-v").hide();
            ui.position.left = pos.left;
          }
          ui.position.left = Math.max( 0, ui.position.left );
          ui.position.top = Math.max( 0, ui.position.top );
          ui.position.left = Math.min( 1280-w, ui.position.left );
          ui.position.top = Math.min( 720-h, ui.position.top );
        }
      });
      $('.iframe-holder').resizable({
        handles: "n, e, s, w, ne, se, sw, nw",
        containment: "parent",
        start: function (event, ui) {
          $(':nth-child(3)', this).show()
        },
        resize: function (event, ui) {
          $(':nth-child(3)> :first-child', this).text(ui.size.width + " X " + ui.size.height)
        },
        stop: function() {
          $(':nth-child(3)', this).hide()
          const url = $(this).data("url");
          const height = $(this).height();
          const width = $(this).width();
          const xPos = $(this).position().left;
          const yPos = $(this).position().top;
          _this.saveSize(url, height, width, xPos, yPos, studio)
        }
      });
    }

    window.addEventListener("message", event => {
      if (event.origin === domain) {
        const { data } = event
        if(data.event === "ELEMENT_RESIZE"){
          window.resizeIframe && window.resizeIframe(data.height, data.width, data.xPos, data.yPos, data.uuid);
        }
        if(data.event === "GUEST_DROPPED" && !guestDropped){
          guestDropped = true
          const xPosition = data.dropX;
          const yPosition = data.dropY;
          let selectedCanvas = null
          const elementsIframeList = document.querySelectorAll(".videofeed-element-iframe")
          for(let elementIframe of elementsIframeList) 
            {
              const IframeRect = elementIframe.getBoundingClientRect();
              if (xPosition >= IframeRect.left && xPosition <= IframeRect.right && yPosition >= IframeRect.top && yPosition <= IframeRect.bottom) {
                selectedCanvas = elementIframe;
                break;
              }
            }

          if (selectedCanvas !== null) {
            parent.postMessage({
              event: "GUEST_VIDEO_DROPPED",
              data:{elementUuid:selectedCanvas.id, guestData:data}
              }, domain);
              guestDropped = false
            } else {
              parent.postMessage({
                event: "NO_ELEMENT_SELECTED",
                data:{guestData:data}
              }, domain);
            }
        }
        if (data.event === "ELEMENT_REMOVED"){
          $(`#iframe-holder-${data.uuid}`).remove()
        }
      }
    })
  }

  saveSize(url, height, width, xPos, yPos, studio) {
    event.preventDefault();
    $.ajax({
      type: "PATCH",
      url: url,
      data: {
        element: {
          height: height,
          width: width,
          xPos: xPos,
          yPos: yPos
        }
      }
    }).done(function(){
      if(!studio){
        window.parent.elementResized(height, width);
      } 
    })
  }

  savePosition(url, xPos, yPos) {
    event.preventDefault();
    $.ajax({
      type: "PATCH",
      url: url,
      data: {
        element: {
          xPos: xPos,
          yPos: yPos
        }
      }
    })
  }

  computeGuidesForElement(elem, pos, w, h) {
    if(elem != null) {
      var $t = $(elem);
      pos = $t.offset();
      w = $t.outerWidth() - 1;
      h = $t.outerHeight() - 1;
    }
    return [
      { type: "h", left: pos.left, top: pos.top },
      { type: "h", left: pos.left, top: pos.top + h },
      { type: "v", left: pos.left, top: pos.top },
      { type: "v", left: pos.left + w, top: pos.top },
      { type: "h", left: pos.left, top: pos.top + h/2 },
      { type: "v", left: pos.left + w/2, top: pos.top }
    ];
  }
}
